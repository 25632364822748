import { Breadcrumb, Button, Card, Col, DatePicker, Form, Row, Select, Space, Table, TableColumnsType } from 'antd';
import Container from 'src/components/Container';
import { useQuery } from '@tanstack/react-query';
import packageRepository, { ReportPackageExcelQuery } from 'src/apis/service/package';
import { useEffect, useState } from 'react';
import { enqueueSnackbar } from 'notistack';
import { GET_LIST_ERROR_MESSAGE } from 'src/constants/message';
import dayjs from 'dayjs';

import { RangePickerProps } from 'antd/es/date-picker';
import { FORMAT_DATE } from 'src/constants/common';
import { RangeValue } from 'rc-picker/lib/interface';
import { saveAs } from 'file-saver';
import { Package } from 'src/types/package';

export default () => {
  const currentDate = dayjs();
  const startDay = currentDate.subtract(28, 'day').toISOString();
  const endDay = currentDate.subtract(20, 'day').toISOString();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [time, setTime] = useState({
    startDay: startDay,
    endDay: endDay,
  });
  const [phoneNumber, setPhoneNumber] = useState<string>('9398');
  const [reportCPStatistic, setReportCPStatistic] = useState<any[]>([]);
  const [filter, setFilter] = useState<any>({
    startDay: startDay,
    endDay: endDay,
    limit: 10,
    page: 1,
    serviceCode: phoneNumber,
  });
  const [packages, setPackages] = useState<Package[]>([]);
  const [packageCode, setPackageCode] = useState<any>();

  const { refetch: refetchRecordOfReconciliation } = useQuery(
    ['getRecordOfReconciliation'],
    () => packageRepository.getRecordOfReconciliation({ ...filter }),
    {
      enabled: true,
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data && phoneNumber === '9398') {
          const dataRecord = data.totalData.totalDataByPackageRegisterRaw.concat(
            data.totalData.totalDataByPackageRegisterFile,
          );
          const mergedData = Object.values(
            dataRecord.reduce((acc: any, item: any) => {
              if (!acc[item.package_code]) {
                acc[item.package_code] = { ...item };
              } else {
                acc[item.package_code].count_raw =
                  Number(acc[item.package_code].count_raw || 0) + Number(item.count_raw || 0);
                acc[item.package_code].count_file =
                  Number(acc[item.package_code].count_file || 0) + Number(item.count_file || 0);
                acc[item.package_code].revenue_raw =
                  Number(acc[item.package_code].revenue_raw || 0) + Number(item.revenue_raw || 0);
                acc[item.package_code].revenue_file =
                  Number(acc[item.package_code].revenue_file || 0) + Number(item.revenue_file || 0);
              }
              return acc;
            }, {} as Record<string, any>),
          );

          setReportCPStatistic(mergedData);
        } else {
          const dataRecord = data.totalInChannel.totalInChannelByPackageRegisterFile
            .concat(data.totalInChannel.totalInChannelByPackageRegisterRaw)
            .concat(data.totalNotInChannel.totalNotInChannelByPackageRegisterFile)
            .concat(data.totalNotInChannel.totalNotInChannelByPackageRegisterRaw);
          const mergedData = Object.values(
            dataRecord.reduce((acc: any, item: any) => {
              if (!acc[item.package_code]) {
                acc[item.package_code] = { ...item };
              } else {
                acc[item.package_code].count_raw_not_in_channel =
                  Number(acc[item.package_code].count_raw_not_in_channel || 0) +
                  Number(item.count_raw_not_in_channel || 0);
                acc[item.package_code].count_file_not_in_channel =
                  Number(acc[item.package_code].count_file_not_in_channel || 0) +
                  Number(item.count_file_not_in_channel || 0);
                acc[item.package_code].revenue_raw_not_in_channel =
                  Number(acc[item.package_code].revenue_raw_not_in_channel || 0) +
                  Number(item.revenue_raw_not_in_channel || 0);
                acc[item.package_code].revenue_file_not_in_channel =
                  Number(acc[item.package_code].revenue_file_not_in_channel || 0) +
                  Number(item.revenue_file_not_in_channel || 0);
                acc[item.package_code].count_raw_in_channel =
                  Number(acc[item.package_code].count_raw_in_channel || 0) + Number(item.count_raw_in_channel || 0);
                acc[item.package_code].count_file_in_channel =
                  Number(acc[item.package_code].count_file_in_channel || 0) + Number(item.count_file_in_channel || 0);
                acc[item.package_code].revenue_raw_in_channel =
                  Number(acc[item.package_code].revenue_raw_in_channel || 0) + Number(item.revenue_raw_in_channel || 0);
                acc[item.package_code].revenue_file_in_channel =
                  Number(acc[item.package_code].revenue_file_in_channel || 0) +
                  Number(item.revenue_file_in_channel || 0);
              }
              return acc;
            }, {} as Record<string, any>),
          );

          setReportCPStatistic(mergedData);
        }
        setIsLoading(false);
      },
    },
  );

  const onClickExportExcel = async (search: ReportPackageExcelQuery) => {
    const filename = `Bien-ban-doi-soat-ngay-${dayjs(time?.startDay).format(FORMAT_DATE)}-den-ngay-${dayjs(
      time?.endDay,
    ).format(FORMAT_DATE)}`;

    try {
      const response = await packageRepository.exportExcelRecordOfReconciliation({ ...search });
      if (response) {
        const blob = new Blob([response], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });

        saveAs(blob, filename);
      }
    } catch (error) {
      enqueueSnackbar(GET_LIST_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  const { refetch: refetchPackages } = useQuery(
    ['getPackages'],
    () => packageRepository.getPackages({ phoneNumber: phoneNumber, limit: 1000, page: 1 }),
    {
      retry: 0,
      refetchOnWindowFocus: false,
      onSuccess: ({ data, success }) => {
        if (success) {
          setPackages(data.items);
        }
      },
    },
  );

  useEffect(() => {
    refetchRecordOfReconciliation();
  }, [filter]);

  useEffect(() => {
    refetchPackages();
  }, [phoneNumber]);

  const handlePickTime = (date: RangeValue<dayjs.Dayjs>) => {
    if (date) {
      setTime({
        startDay: dayjs(date[0]).startOf('day').format('YYYY-MM-DD'),
        endDay: dayjs(date[1]).endOf('day').format('YYYY-MM-DD'),
      });
    } else {
      setFilter({ ...filter, startDay: startDay, endDay: endDay });
    }
  };

  const columns: TableColumnsType<any> = [
    {
      title: 'STT',
      render: (_, __, number) => <>{number}</>,
    },
    {
      title: 'Mã gói cước',
      dataIndex: 'package_code',
      key: 'package_code',
    },
    {
      title: 'Register_File',
      children: [
        {
          title: 'Lượt tải',
          dataIndex: 'count_file',
          key: 'count_file',
          align: 'left',
          render: (count_file) => <>{count_file ?? 0}</>,
        },
        {
          title: 'Doanh thu',
          dataIndex: 'revenue_file',
          key: 'revenue_file',
          render: (revenue_file) => <>{revenue_file ?? 0}</>,
        },
      ],
    },
    {
      title: 'Register_Raw',
      children: [
        {
          title: 'Lượt tải',
          dataIndex: 'count_raw',
          key: 'count_raw',
          align: 'left',
          render: (count_raw) => <>{count_raw ?? 0}</>,
        },
        {
          title: 'Doanh thu',
          dataIndex: 'revenue_raw',
          key: 'revenue_raw',
          align: 'left',
          render: (revenue_raw) => <>{revenue_raw ?? 0}</>,
        },
      ],
    },
    {
      title: 'Lượt tải chênh lệch',
      render: (_, record) => (
        <>
          {Number(record?.count_raw ?? 0) - Number(record?.count_file ?? 0) > 0
            ? Number(record?.count_raw ?? 0) - Number(record?.count_file ?? 0)
            : Number(record?.count_raw ?? 0) - Number(record?.count_file ?? 0) * -1}
        </>
      ),
    },
  ];

  const colums_999: TableColumnsType<any> = [
    {
      title: 'STT',
      render: (_, __, number) => <>{number}</>,
    },
    {
      title: 'Mã gói cước',
      dataIndex: 'package_code',
      key: 'package_code',
    },
    {
      title: 'Register_File',
      children: [
        {
          title: 'Lượt tải',
          align: 'center',
          children: [
            {
              title: 'Qua KPP',
              dataIndex: 'count_file_in_channel',
              key: 'count_file_in_channel',
              align: 'center',
              render: (count_file_in_channel) => <>{count_file_in_channel ?? 0}</>,
            },
            {
              title: 'Không qua KPP',
              dataIndex: 'count_file_not_in_channel',
              key: 'count_file_not_in_channel',
              align: 'center',
              render: (count_file_not_in_channel) => <>{count_file_not_in_channel ?? 0}</>,
            },
          ],
        },
        {
          title: 'Doanh thu',
          render: (_, record) => (
            <>{(record?.revenue_file_in_channel ?? 0) + (record?.revenue_file_not_in_channel ?? 0)}</>
          ),
        },
      ],
    },
    {
      title: 'Register_Raw',
      children: [
        {
          title: 'Lượt tải',
          dataIndex: 'count_raw',
          key: 'count_raw',
          align: 'center',
          children: [
            {
              title: 'Qua KPP',
              dataIndex: 'count_raw_in_channel',
              key: 'count_raw_in_channel',
              align: 'center',
              render: (count_raw_in_channel) => <>{count_raw_in_channel ?? 0}</>,
            },
            {
              title: 'Không qua KPP',
              dataIndex: 'count_raw_not_in_channel',
              key: 'count_raw_not_in_channel',
              align: 'center',
              render: (count_raw_not_in_channel) => <>{count_raw_not_in_channel}</>,
            },
          ],
        },
        {
          title: 'Doanh thu',
          dataIndex: 'revenue_raw',
          key: 'revenue_raw',
          align: 'left',
          render: (_, record) => (
            <>{(record?.revenue_raw_in_channel ?? 0) + (record?.revenue_raw_not_in_channel ?? 0)}</>
          ),
        },
      ],
    },
    {
      title: 'Lượt tải chênh lệch',
      render: (_, record) => (
        <>
          {Number(record?.count_file_in_channel ?? 0) +
            Number(record?.count_file_not_in_channel ?? 0) -
            Number(record?.count_raw_in_channel ?? 0) +
            Number(record?.count_raw_not_in_channel ?? 0) >=
          0
            ? Number(record?.count_file_in_channel ?? 0) +
              Number(record?.count_file_not_in_channel ?? 0) -
              Number(record?.count_raw_in_channel ?? 0) +
              Number(record?.count_raw_not_in_channel ?? 0)
            : (Number(record?.count_file_in_channel ?? 0) +
                Number(record?.count_file_not_in_channel ?? 0) -
                Number(record?.count_raw_in_channel ?? 0) +
                Number(record?.count_raw_not_in_channel ?? 0)) *
              -1}
        </>
      ),
    },
  ];

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current > dayjs().startOf('day');
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Breadcrumb
        items={[
          {
            title: (
              <p style={{ cursor: 'default' }} onClick={() => {}}>
                Báo cáo
              </p>
            ),
          },
          {
            title: (
              <p style={{ cursor: 'default' }} onClick={() => {}}>
                Biên bản đối soát
              </p>
            ),
          },
        ]}
      />
      <Card size="small">
        <Row gutter={24}>
          <Col span={6}>
            <Form.Item required={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
              <DatePicker.RangePicker
                format={FORMAT_DATE}
                style={{ width: '100%' }}
                onChange={(date) => handlePickTime(date)}
                defaultValue={[dayjs(filter?.startDay), dayjs(filter?.endDay)]}
                disabledDate={disabledDate}
                allowClear={false}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item required={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
              <Select onChange={(e) => setPhoneNumber(e)} defaultValue={'9398'}>
                <Select.Option value={'999'}>999</Select.Option>
                <Select.Option value={'9398'}>9398</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item required={false} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} style={{ width: '100%' }}>
              <Select
                placeholder="Chọn gói cước"
                onChange={(e) => {
                  if (e) {
                    setPackageCode(e);
                  } else {
                    setPackageCode(e);
                    setFilter({ ...filter, page: 1, packageCode: undefined });
                    setIsLoading(true);
                  }
                }}
                allowClear
              >
                {packages?.map(({ code }) => (
                  <Select.Option value={code}>{code}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                onClick={() => {
                  setFilter({
                    ...filter,
                    startDay: time.startDay,
                    endDay: time.endDay,
                    serviceCode: phoneNumber,
                    packageCode,
                  }),
                    setIsLoading(true);
                }}
              >
                Tìm kiếm
              </Button>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                onClick={() =>
                  onClickExportExcel({
                    ...filter,
                    startDay: time.startDay,
                    endDay: time.endDay,
                    serviceCode: phoneNumber,
                    packageCode,
                  })
                }
              >
                Xuất excel
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Container title="Báo cáo cho CP">
        <Table
          bordered
          columns={filter?.serviceCode === '9398' ? columns : colums_999}
          dataSource={reportCPStatistic}
          pagination={false}
          loading={isLoading}
        />
      </Container>
    </Space>
  );
};
